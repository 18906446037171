import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <section className={styles.banner}>
      <Container>
        <Row className="align-items-center gap-30">
          <Col lg={6} md={12}>
            <div className={styles.mainHeading}>
              <h1 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />

              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>

            <ul className="pr-2 pr-md-0">
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => <li key={i}>{e?.title}</li>)}
            </ul>
            <div className={styles.button}>
              <Link
                to={strapiData?.buttons[0]?.url}
                variant="light "
                className="btn_black_border"
              >
                {strapiData?.buttons[0]?.title}
              </Link>{" "}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <div className={styles.bannerImg}>
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={"Hire Cloud Engineers"}
                width="100%"
                decoding="async"
                loading="lazy"
                height="auto"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
