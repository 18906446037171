import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./DataEngNetwork.module.scss"

const DataEngNetwork = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <div className={styles.dataengHeading}>
          <h2
            className="text-center mb-4 mt-4"
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />

          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="align-items-start gap-30 mt-5">
          {strapiData?.cards?.map((item, index) => (
            <Col sx={12} lg={6} xl={4} key={index}>
              <div className={`p-4 ${styles.card}`}>
                {item?.image1 && (
                  <img
                    decoding="async"
                    loading="lazy"
                    className="mb-4"
                    src={item?.image1[0]?.localFile?.publicURL}
                    alt="one"
                  />
                )}
                <h3
                  className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: item?.title,
                  }}
                />
                <p className="mb-4">{item?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5">
          <Link
            to={strapiData?.buttons[0]?.url}
            variant="light "
            className="btn_black_border"
          >
            {strapiData?.buttons[0]?.title}
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default DataEngNetwork
