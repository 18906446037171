import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Line from "../../images/hire-data-engineer/line.svg"
import * as styles from "./EasySteps.module.scss"

const EasySteps = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <h2
          className="text-center mb-4 mt-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="align-items-start gap-30 mt-5">
          <Col sx={12} lg={6}>
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt="FeaturedImg"
            />
          </Col>
          <Col sx={12} lg={6}>
            <div className={styles.cardMain}>
              <img
                decoding="async"
                loading="lazy"
                className={styles.line}
                src={Line}
                alt="line"
              />
              {strapiData?.cards?.map(item => (
                <div className={styles.card} key={item?.title}>
                  <div className={styles.icon}>
                    {item?.image1 && (
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.image1[0]?.localFile?.publicURL}
                        alt="one"
                      />
                    )}
                  </div>
                  <div>
                    <p>{item?.subTitle}</p>
                    <h3>{item?.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <Link
            to={strapiData?.buttons[0]?.url}
            variant="light "
            className="btn_black_border"
          >
            {strapiData?.buttons[0]?.title}
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default EasySteps
