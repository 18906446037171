import { graphql } from "gatsby"
import React from "react"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Approach from "../components/hire-data-engineer/Approach"
import Banner from "../components/hire-data-engineer/Banner"
import DataEngNetwork from "../components/hire-data-engineer/DataEngNetwork"
import EasySteps from "../components/hire-data-engineer/EasySteps"
import Infrastructure from "../components/hire-data-engineer/Infrastructure"
import TechnologyStack from "../components/hire-data-engineer/TechnologyStack"
import MainLayout from "../layouts/MainLayout"
import "../components/hire-data-engineer/hiredata.scss"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_data_engineers_eb9b2333be.webp"
    />
  )
}
const hireDataEngineer = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const dataEng = data?.strapiPage?.sections[1]
  const infrastructure = data?.strapiPage?.sections[2]
  const easysteps = data?.strapiPage?.sections[3]
  const engagement = data?.strapiPage?.sections[4]
  const stack = data?.strapiPage?.sections[5]
  const approach = data?.strapiPage?.sections[6]
  const faq = data?.strapiPage?.sections[7]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={banner} />
      <DataEngNetwork strapiData={dataEng} />
      <Infrastructure strapiData={infrastructure} />
      <EasySteps strapiData={easysteps} />
      <div className="paddingTopEngagement">
        <EngagementModels strapiData={engagement} />
      </div>
      <TechnologyStack strapiData={stack} />
      <Approach strapiData={approach} />
      <Faqs strapiData={faq} />
    </MainLayout>
  )
}

export const query = graphql`
  query hireDataEngineer {
    strapiPage(slug: { eq: "hire-data-engineer" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default hireDataEngineer
