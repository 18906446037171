import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechnologyStack.module.scss"

const TechnologyStack = ({ strapiData }) => {
  return (
    <section className={styles.stack}>
      <Container>
        <div className={styles.stackHeading}>
          <h2
            className="text-center mb-4 "
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <div className={styles.card}>
          {strapiData?.cards?.map(item => (
            <div key={item?.title}>
              <img
                decoding="async"
                loading="lazy"
                className="mb-4"
                src={item?.image1[0]?.localFile?.publicURL}
                alt="Python"
              />
              <h3 className="text-center mb-5">{item?.title}</h3>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default TechnologyStack
