// extracted by mini-css-extract-plugin
export var backHead = "Infrastructure-module--backHead--5cd47";
export var banner = "Infrastructure-module--banner--ed209";
export var card = "Infrastructure-module--card--b947f";
export var flipbackText = "Infrastructure-module--flipbackText--81fae";
export var flipbox = "Infrastructure-module--flipbox--213b2";
export var flipboxback = "Infrastructure-module--flipboxback--33067";
export var flipboxfront = "Infrastructure-module--flipboxfront--8cc4d";
export var flipboxinner = "Infrastructure-module--flipboxinner--e6b90";
export var hr = "Infrastructure-module--hr--533e5";
export var unlockHeading = "Infrastructure-module--unlockHeading--2432c";