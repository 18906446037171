import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Approach.module.scss"

const Approach = ({ strapiData }) => {
  return (
    <section className={styles.banner}>
      <Container>
        <div className={styles.approachHeading}>
          <h2
            className="text-center mb-4 mt-4"
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />

          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <div className="text-center">
          <Link
            to={strapiData?.buttons[0]?.url}
            variant="light "
            className="btn_white_border"
          >
            {strapiData?.buttons[0]?.title}
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default Approach
