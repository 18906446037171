import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Infrastructure.module.scss"

const Infrastructure = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <div className={styles.unlockHeading}>
          <h2
            className="text-center mb-4"
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="align-items-start gap-30 mt-5">
          {strapiData?.cards?.map(item => (
            <Col sx={12} lg={6} xl={4} key={item?.title}>
              <div className={styles.flipbox}>
                <div className={styles.flipboxinner}>
                  <div className={styles.flipboxfront}>
                    <div className={`p-4 ${styles.card}`}>
                      {item?.image1 && (
                        <img
                          decoding="async"
                          loading="lazy"
                          className="mb-4"
                          src={item?.image1[0]?.localFile?.publicURL}
                          alt="one"
                        />
                      )}
                      <h3
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}
                      />
                      <p className="mb-4">{item?.subTitle}</p>
                    </div>
                  </div>
                  <div className={styles.flipboxback}>
                    <div className={`p-4 ${styles.card}`}>
                      <p
                        className={styles.backHead}
                        dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}
                      />
                      <div className={styles.flipbackText}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Infrastructure
